jQuery(function($) {

    windowWidth = window.innerWidth;
	let windowSp = 600;
    // メニュー表示アニメーション
    $('.js-menu-btn').on('click', function() {

        $('#m-menu').css('display', 'block');

        //0. 背景のスクロールを停止する
        setTimeout(function() {
            $('body').addClass('no-scroll');
        }, 1);
        
        // 1.ヘッダーの表示
        setTimeout(function() {
            $('#js-header').animate(
                {
                    height: '100%',
                }, 300, 'swing')
        }, 200);

        // 2.ロゴとボタンの表示
        setTimeout(function() {
            $('#js-logo').animate(
                {
                    opacity: '1',
                }, 400, 'swing')
            
            $('#js-close-btn').animate(
                {
                    opacity: '1',
                }, 400, 'swing');
        }, 500);

        // 3.menu本体の表示
        if( windowWidth > windowSp ) {
            // スマホ以外の時
            setTimeout(function() {
            
                $('#js-menu-ml').animate(
                    {
                        width: '100%',
                    }, 600, 'swing');
    
                $('#js-menu-srv').animate(
                    {
                        width: '100%',
                    }, 600, 'swing');
    
                
            }, 800);
        } else {
            // スマホの時
            setTimeout(function() {
            
                $('#m-menu-main').addClass('show');
                
            }, 800);

        }

        // 4.メニューコンテンツの表示
        setTimeout(function() {

            $('#js-menu-ml-content').addClass('show');
            $('#js-menu-srv-content').addClass('show');
            
        }, 1500);


    });

    // menu非表示
    $('#js-close-btn').on('click', function() {

        //0. 背景のスクロールを停止する
        setTimeout(function() {
            $('body').removeClass('no-scroll');
        }, 1);

        // 1.メニューコンテンツの非表示
        setTimeout(function() {
            
            $('#js-menu-ml-content').addClass('out');
            $('#js-menu-srv-content').addClass('out');
            
        }, 0);

        // 2.menu本体の非表示
        if( windowWidth > windowSp ) {

            //スマホ以外の時
            setTimeout(function() {
                $('#js-menu-ml').animate(
                    {
                        width: '0',
                    }, 700, 'swing');
    
                $('#js-menu-srv').animate(
                    {
                        width: '0',
                    }, 700, 'swing');
    
                
            }, 500);

        } else {
            //スマホの時
            setTimeout(function() {
            
                $('#m-menu-main').addClass('hide');
                
            }, 500);

        }

        // 3.ロゴとボタンの非表示
        setTimeout(function() {
            $('#js-logo').animate(
                {
                    opacity: '0',
                }, 600, 'swing')
            
            $('#js-close-btn').animate(
                {
                    opacity: '0',
                }, 600, 'swing');
        }, 1000);

        // 4.ヘッダーの非表示
        setTimeout(function() {
            $('#js-header').animate(
                {
                    height: '0',
                }, 400, 'swing')
        }, 1300);
        
        setTimeout(function() {
            $('#js-menu-ml-content').removeClass('show');
            $('#js-menu-srv-content').removeClass('show');
            $('#js-menu-ml-content').removeClass('out');
            $('#js-menu-srv-content').removeClass('out');
            $('#m-menu-main').removeClass('show').removeClass('hide');

            $('#m-menu').css('display', 'none');
        }, 1700);

    });// menu非表示



    // footer固定
    let footFix = function() {

        let footer = $('footer'),
            footerPos = footer.offset().top;
            mtp = $('#main-top-page'),
            winH = $(window).height()
            targetPos = footerPos - winH * 2;

        $(window).on('scroll', function() {

            scroll = $(window).scrollTop();

            if( scroll >= targetPos) {

                //footer固定と同時に一番上から固定されているfixed要素を非表示に
                footer.addClass('fixed');
                mtp.addClass('back-on');

            } else {

                footer.removeClass('fixed');
                mtp.removeClass('back-on');
            }
            
        });
    }

    footFix();
    
});